body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to bottom, #3772FF, #080708,  #2f4734	); */
  /* background: linear-gradient(to bottom, #191325, #0D0A14, #181325) ; */
  /* background: linear-gradient(to bottom left, #191325 10%, #0D0A14 0%, #0D0A14 0%, #181325 10%); */
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  background: linear-gradient(to bottom left, #9C78ED80
  0%, #0D0A14 0%, #0D0A14 0%, #181325 50%);
  overflow: hidden;
  box-sizing: border-box;
  
  /* background: linear-gradient(
    to bottom,
   rgba(55, 114, 255, 1)
    #080708, 
    #2f4734	
); */
}
/* body::before {
  content: '';
  position: relative;
  top: 50px;
  left: 0%;
  width: 100%;
  border-radius: 50%;
  height: 50%;
  background: linear-gradient(to bottom left, #9C78ED80 100%, transparent 50%);
  filter: blur(100.6px);
  pointer-events: none; 
  z-index:  1000000;
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* menu font */

@font-face {
  font-family: "Lexend";
  src: url("./fonts/Lexend/Lexend-VariableFont_wght.ttf");
  src: url("./fonts/Lexend/static/Lexend-Thin.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-Black.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-Bold.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-ExtraBold.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-ExtraLight.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-Light.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-Medium.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-Regular.ttf")format("truetype");
  src: url("./fonts/Lexend/static/Lexend-SemiBold.ttf")format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf")format("truetype");
  src: url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-Black.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-BlackItalic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-Bold.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-BlackItalic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-ExtraBold.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-ExtraLight.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-Italic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-Light.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-LightItalic.ttf")format("truetype");
  src: url("./fonts/Montserrat/static/Montserrat-Medium.ttf")format("truetype");
}




@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}



@keyframes bouncyAnimation {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bouncy-button {
  animation: bouncyAnimation 1.5s infinite;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  color: #fff;
}


.goog-te-gadget-simple{
  background: #000;
  border: #000;
}

.VIpgJd-ZVi9od-vH1Gmf {
  background: #000 !important;
}



@keyframes drumstickAnimation1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes drumstickAnimation2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}



.capitalize-first {
  text-transform: capitalize;
}

.css-ofrcno-MuiGrid-root>.MuiGrid-item{
  padding-top: 7px !important;
}

.css-3nargb > .MuiGrid-item {
  padding-top: 7px !important;
}

.css-3nargb > .MuiGrid-item {
  padding-top: 7px !important;
}